import React, {useEffect} from 'react';
import "./MenuIcon.css"

const MenuIcon = () => {
    useEffect(() => {
    }, []);

    return (
        <div className={"flexCenter containerMenuIcon buttonLike"}>
            <div className={"rectangleBar firstBar buttonLike"}/>
            <div className={"rectangleBar secondBar buttonLike"}/>
            <div className={"rectangleBar thirdBar buttonLike"}/>
        </div>
    );
};

export default MenuIcon;