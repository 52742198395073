import React, {Component} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Axios from "axios";

//Content
import Portfolio from "./content/Portfolio";
import {withRouter} from "./hooks/NavigationUtils";

const correctPassword = "portfoliolr"; // Oui c'est pas sécurisé mais c'est temporaire

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      password: '',
      errorMessage: ''
    };
  }

  askPassword(){
      // Loop until the correct password is entered
    let password;
    password = prompt("Entrez le mot de passe pour accéder au site :");
    // If the prompt is closed or the password is incorrect
    if (password === null || password !== correctPassword) {
      alert("Mot de passe incorrect.");
      this.askPassword();
    }
  }

  componentDidMount() {
    // Display image in console
    // fetch(image).then(r=>{
    //     r.text().then(t=>{
    //       console.log("%c" + "test", "background-image: url('"+t+"') ; color: transparent; font-size: 300px; background-size: contain; background-repeat: no-repeat;")
    //     })
    // })

    // this.askPassword();

    // Add a request interceptor
    Axios.interceptors.request.use(function (config) {
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    // Add a response interceptor
    Axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      console.log(error)
      if(error.response.status === 404){
        alert("Err 404 !\nThis resource does not exist.")
      }else if(error.response.status === 503){
        alert("Err 503 !\nThis website is under maintenance.")
      }else if(error.response.status === 403){
        alert("Err 403 !\nYou are not authorized to access this resource.")
      }
      return Promise.reject(error);
    });
  }

  render(){
    return (
        <>
          <Routes>
            <Route path="/Portfolio" element={<Portfolio/>} />
            <Route path="*" element={<Navigate to="/Portfolio"/>} />
          </Routes>
        </>
    );}
}
export default withRouter(App)