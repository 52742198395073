import React from 'react';

// Select the circle element
let circleElement = document.querySelector('.circle');

// Create objects to track mouse position and custom cursor position
const mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 }; // Track the mouse position
const previousMouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 } // Store the previous mouse position
const circle = { x: window.innerWidth / 2, y: window.innerHeight / 2 }; // Track the circle position

// Initialize variables to track scaling and rotation
let currentScale = 0; // Track current scale value
let currentAngle = 0; // Track current angle value

const speed = 0.40;

tick();
function tick() {
    if(!circleElement){
        circleElement = document.querySelector('.circle');
    }

    circle.x += (mouse.x - circle.x) * speed;
    circle.y += (mouse.y - circle.y) * speed;
    // Create a transformation string for cursor translation
    const translateTransform = `translate(${circle.x}px, ${circle.y}px)`;

    // SQUEEZE
    // 1. Calculate the change in mouse position (deltaMouse)
    const deltaMouseX = mouse.x - previousMouse.x;
    const deltaMouseY = mouse.y - previousMouse.y;
    // Update previous mouse position for the next frame
    previousMouse.x = mouse.x;
    previousMouse.y = mouse.y;
    // 2. Calculate mouse velocity using Pythagorean theorem and adjust speed
    const mouseVelocity = Math.min(Math.sqrt(deltaMouseX**2 + deltaMouseY**2) * 4, 150);
    // 3. Convert mouse velocity to a value in the range [0, 0.5]
    const scaleValue = (mouseVelocity / 200) * 0.9;
    // 4. Smoothly update the current scale
    currentScale += (scaleValue - currentScale) * speed;
    // 5. Create a transformation string for scaling
    const scaleTransform = `scale(${1 + currentScale}, ${1 - currentScale})`;

    // ROTATE
    // 1. Calculate the angle using the atan2 function
    const angle = Math.atan2(deltaMouseY, deltaMouseX) * 180 / Math.PI;
    // 2. Check for a threshold to reduce shakiness at low mouse velocity
    if (mouseVelocity > 20) {
        currentAngle = angle;
    }
    // 3. Create a transformation string for rotation
    const rotateTransform = `rotate(${currentAngle}deg)`;

    // Apply all transformations to the circle element in a specific order: translate -> rotate -> scale
    if(circleElement){
        circleElement.style.transform = `${translateTransform} ${rotateTransform} ${scaleTransform}`;
    }

    // Request the next frame to continue the animation
    window.requestAnimationFrame(tick);
}

window.addEventListener('mousemove', (e) => {
    mouse.x = e.x;
    mouse.y = e.y;
});

//add listener to check if mouse is clicking on the scroll bar
window.addEventListener('mousedown', (e) => {
    if(e.clientX > window.innerWidth - 15){
        circleElement.style.opacity = "0";
    }else{
        //reduce the --circle-size
        circleElement.style.setProperty('--circle-size', "30px");
    }
});

window.addEventListener('mouseup', (e) => {
    circleElement.style.opacity = "1";
    circleElement.style.setProperty('--circle-size', "35px");
})

//add listener to check if mouse is hovering an element
window.addEventListener('mouseover', (e) => {
    //if element contains classname selectableText
    if(e.target && e.target.classList && !!circleElement){
        let all = ["menu", "welcome", "list"]
        //get parent that contains either of the string in all in its id
        let parent = e.target.parentElement
        if(parent){
            while(parent && !all.includes(parent.id)){
                parent = parent.parentElement
            }
        }

        if((e.target.classList.contains("selectableText") || e.target.classList.contains("buttonLike")) && ((parent && parent.style.zIndex !== "0") || !parent)){
            circleElement.classList.add("glassDiv")
            circleElement.style.border = "none"
        }else{
            circleElement.classList.remove("glassDiv")
            circleElement.style.border = "2px solid white"
        }
    }

});

const CircleFollower = () => {
    return (
        <div className="circle"/>
    );
};

export default CircleFollower;